import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import dayjs from 'dayjs'

import App from './App.vue'
import router from './router'
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
// 插件绑定到Vue原型链上
app.config.globalProperties.$dayjs = dayjs
app.mount('#app')